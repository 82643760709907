import React, { useEffect, useState} from 'react';
import './profile.scss';
import {useAuth} from "../../contexts/auth";
import {providerDataCRUD, providerProfileCRUD} from "../../http";
import ProfileUser from "../../components/profile/ProfileUser";
import ProfileBank from "../../components/profile/ProfileBank";
import ProfileProvider from "../../components/profile/ProfileProvider";


export default function Profile() {
    const {user, provider} = useAuth();

    const [dataUser, setDataUser] = useState({})
    const [dataProvider, setDataProvider] = useState({})

    const handleSaveUserData = async () => {
        await providerProfileCRUD.create(dataUser)
    };

    const handleSaveProviderData = async () => {
        await providerDataCRUD.create(dataProvider)
    };

    useEffect(() => {
        if (user) {
            setDataUser(user)
        }
    }, [user])

    useEffect(() => {
        if (provider) {
            setDataProvider(provider)
        }
    }, [provider])

    function formFieldDataChanged(e) {
        setDataUser(e.component.option("formData"))
    }

    function formFieldProviderDataChanged(e) {
        setDataProvider(e.component.option("formData"))
    }

    return (
        <React.Fragment>
            <ProfileUser dataUser={dataUser} formFieldDataChanged={formFieldDataChanged} handleSaveUserData={handleSaveUserData}/>
            <ProfileBank dataProvider={dataProvider} handleSaveUserData={handleSaveProviderData} formFieldDataChanged={formFieldProviderDataChanged}/>
            <ProfileProvider dataProvider={dataProvider} handleSaveUserData={handleSaveProviderData} formFieldDataChanged={formFieldProviderDataChanged}/>
        </React.Fragment>
    );
}
