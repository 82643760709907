import React, {createContext, useCallback, useContext, useEffect, useState} from 'react';
import {getUser, logout, signIn as sendSignInRequest} from '../api/auth';


function AuthProvider(props) {
    const [user, setUser] = useState();
    const [provider, setProvider] = useState({});
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        (async function () {
            const result = await getUser();
            if (result.isOk) {
                setUser(result.data);
                setProvider(result.dataProvider);
            }

            setLoading(false);
        })();
    }, []);

    const signIn = useCallback(async (email, password) => {
        const result = await sendSignInRequest(email, password);
        if (result.isOk) {
            setUser(result.data);
            setProvider(result.dataProvider);
        }

        return result;
    }, []);

    const signOut = useCallback(async () => {
        await logout();
        setUser(undefined);
        setProvider({});
    }, []);


    return (
        <AuthContext.Provider value={{user, signIn, provider, signOut, loading}} {...props} />
    );
}

const AuthContext = createContext({loading: false});
const useAuth = () => useContext(AuthContext);

export {AuthProvider, useAuth}
