import defaultUser from '../utils/default-user';
import {$authHost, $host} from '../http/index'

export async function signIn(email, password) {
  try {
      const {data: {token, user, provider}} = await $host.post('/loginProvider', {
          email,
          password
      })


      if (token) {
          localStorage.setItem('token', token)
          return {
              isOk: true,
              data: user,
              dataProvider: provider,
          };
      }

  }
  catch (e) {
    return {
      isOk: false,
      message: e.response?.data?.message || "Authentication failed"
    };
  }
}

export async function getUser() {
  try {
      if (!localStorage.getItem('token')) {
          return {
              isOk: false
          };
      }

      const {data: {token, user, provider}} = await $authHost.get('/check')

      if (token) {
          localStorage.setItem('token', token)
          return {
              isOk: true,
              data: user,
              dataProvider: provider,
          };
      } else {
          return {
              isOk: false,
              data: defaultUser
          };
      }
  }
  catch {
    return {
      isOk: false
    };
  }
}

export async function logout() {
  try {
      await $authHost.post('/logout')
      localStorage.removeItem('token')
  }
  catch {
    return {
      isOk: false,
      message: "Ошибка при выходе из аккаунта"
    };
  }
}
