import React from 'react';
import Form, {ButtonItem, EmailRule, GroupItem, Label, RequiredRule, SimpleItem} from "devextreme-react/form";

function ProfileProvider({dataProvider, handleSaveUserData, formFieldDataChanged}) {
    let buttonOptions = {
        text: 'Сохранить',
        type: 'success',
        useSubmitBehavior: true,
        onClick: handleSaveUserData
    };
    let phoneEditorOptions = {
        mask: '+7 (000) 000-0000',
        maskRules: {
            X: /[01-9]/,
        },
    };
    return (
        <React.Fragment>
            <h2 className={'content-block'}>Основная информация</h2>
            <div className={'content-block dx-card responsive-paddings'}>
                <Form
                    formData={dataProvider}
                    showColonAfterLabel={true}
                    onFieldDataChanged={formFieldDataChanged}
                    showValidationSummary={true}
                    validationGroup="customerData"
                    labelLocation={'top'}
                >
                    <GroupItem cssClass="second-group" colCount={2}>
                        <GroupItem>
                            <SimpleItem dataField="name">
                                <Label text={`Название`} />
                            </SimpleItem>
                            <SimpleItem dataField="address">
                                <Label text={`Адрес`} />
                            </SimpleItem>
                        </GroupItem>
                        <GroupItem>
                            <SimpleItem editorOptions={phoneEditorOptions} dataField="phone2">
                                <Label text={`Телефон для клиентов`} />
                            </SimpleItem>
                            <SimpleItem editorOptions={phoneEditorOptions} dataField="phone3">
                                <Label text={`Телефон для связи с менеджером проекта`} />
                            </SimpleItem>
                        </GroupItem>
                        <GroupItem>
                            <SimpleItem dataField="email">
                                <Label text={`Почта`} />
                                <EmailRule message="Некорректный email"/>
                            </SimpleItem>
                            <SimpleItem dataField="email2">
                                <Label text={`Почта для выставления счетов`} />
                                <EmailRule message="Некорректный email"/>
                            </SimpleItem>
                        </GroupItem>
                        <GroupItem>
                            <SimpleItem dataField="telegram">
                                <Label text={`Телеграм`} />
                            </SimpleItem>
                            <SimpleItem dataField="whatsapp">
                                <Label text={`WhatsApp`} />
                            </SimpleItem>
                        </GroupItem>
                        <GroupItem>
                            <SimpleItem dataField="percentDiscount">
                                <Label text={`Процент скидки`} />
                            </SimpleItem>
                        </GroupItem>
                    </GroupItem>
                    <ButtonItem horizontalAlignment="left"
                                buttonOptions={buttonOptions}
                    />
                </Form>
            </div>
        </React.Fragment>
    );
}

export default ProfileProvider;
