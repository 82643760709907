import { HomePage, ServicesPage, ProfilePage } from './pages';
import { withNavigationWatcher } from './contexts/navigation';
import ServicePage from "./pages/services/ServicePage";

const routes = [
    {
        path: '/services',
        element: ServicesPage
    },
    {
        path: '/services/:id',
        element: ServicePage
    },
    {
        path: '/profile',
        element: ProfilePage
    },
    {
        path: '/home',
        element: HomePage
    }
];

export default routes.map(route => {
    return {
        ...route,
        element: withNavigationWatcher(route.element, route.path)
    };
});
