import React from 'react';
import 'devextreme/data/odata/store';
import DataGrid, {
    Column,
    Pager,
    Paging,
    FilterRow,
    Button
} from 'devextreme-react/data-grid';
import {getServiceDataStore} from "../../datastore";
import DataSource from "devextreme/data/data_source";
import {useNavigate} from "react-router-dom";
import {Popup, Position, ToolbarItem} from "devextreme-react/popup";

const service = getServiceDataStore();

const serviceDataStore = new DataSource({
    store: service,
    reshapeOnPush: true,
});

export default function Services() {
    const navigate = useNavigate();
    const [editedRow, setEditedRow] = React.useState({});
    const [deletedRowKey, setDeletedRowKey] = React.useState(null);
    const [deleteRowPopupVisible, setDeleteRowPopupVisible] = React.useState(false);
    const datagridRef = React.useRef(null);

    const openEditor = (data) => {
        const {row: {data: {id}}} = data
        navigate(`/services/${id}`)
    }

    const openDeleteRowPopup = (e) => {
        setEditedRow({});
        setDeletedRowKey(e.row.key);
        setDeleteRowPopupVisible(true);
    }
    const closeDeleteRowPopup = () => {
        setDeleteRowPopupVisible(false);
    };

    const deleteEntry = (e) => {
        service
            .store()
            .remove(deletedRowKey)
            .then(() => {
                closeDeleteRowPopup();
                datagridRef?.current?.instance?.refresh();
            })
            .catch((error) => {
                closeDeleteRowPopup();
            });
    };

    return (
        <React.Fragment>
            <h2 className={'content-block'}>Ваши услуги</h2>
            {deleteRowPopupVisible && (
                <Popup
                    className="deleteRowPopup"
                    width="auto"
                    height="auto"
                    visible={true}
                    showCloseButton={false}
                    showTitle={false}
                    onHiding={closeDeleteRowPopup}
                >
                    <Position my="center" at="center" of={window}/>
                    <div>
                        <span>Вы уверены, что хотите удалить эту запись?</span>
                    </div>
                    <ToolbarItem
                        widget="dxButton"
                        location="center"
                        options={{text: "Да", onClick: deleteEntry}}
                        toolbar="bottom"
                    />
                    <ToolbarItem
                        widget="dxButton"
                        location="center"
                        options={{text: "Нет", onClick: closeDeleteRowPopup}}
                        toolbar="bottom"
                    />
                </Popup>
            )}

            <DataGrid
                className="content-block"
                dataSource={serviceDataStore}
                showBorders={false}
                ref={datagridRef}
                defaultFocusedRowIndex={0}
                columnAutoWidth={true}
                columnHidingEnabled={true}
            >

                <Paging defaultPageSize={10}/>
                <Pager showPageSizeSelector={true} showInfo={true}/>
                <FilterRow visible={true}/>

                <Column dataField={'id'} caption="Идентификатор"/>
                <Column dataField={'name'} caption="Название"/>
                <Column dataField={'active'} caption="Активная"/>
                <Column
                    dataField="tags"
                    dataType="string"
                    caption="Теги"
                    cellComponent={(e) => {
                        return (
                            <div className="smartStations-row">
                                {
                                    e.data?.value?.length ? e.data?.value.map(el => {
                                        return el.name
                                    }) : null
                                }
                            </div>
                        );
                    }}
                />
                <Column
                    dataField="city"
                    dataType="string"
                    caption="Города"
                    cellComponent={(e) => {
                        return (
                            <div className="smartStations-row">
                                {
                                    e.data?.value?.length ? e.data?.value.map(el => {
                                        return el.name
                                    }) : null
                                }
                            </div>
                        );
                    }}
                />
                <Column type="buttons">
                    <Button name="custom-edit" text="Изменить" icon="edit" onClick={openEditor}/>
                    <Button
                        name="custom-delete"
                        text="Удалить"
                        icon="trash"
                        onClick={openDeleteRowPopup}
                    />
                </Column>
            </DataGrid>
        </React.Fragment>
    )
}

