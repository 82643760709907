import React from 'react';
import Form, {ButtonItem, GroupItem, Label, RequiredRule, SimpleItem} from "devextreme-react/form";

function ProfileBank({dataProvider, handleSaveUserData, formFieldDataChanged}) {
    let buttonOptions = {
        text: 'Сохранить',
        type: 'success',
        useSubmitBehavior: true,
        onClick: handleSaveUserData
    };
    return (
        <React.Fragment>
            <h2 className={'content-block'}>Банковские реквизиты</h2>
            <div className={'content-block dx-card responsive-paddings'}>
                <Form
                    formData={dataProvider}
                    showColonAfterLabel={true}
                    onFieldDataChanged={formFieldDataChanged}
                    showValidationSummary={true}
                    validationGroup="customerData"
                    labelLocation={'top'}
                >
                    <GroupItem cssClass="second-group" colCount={2}>
                        <GroupItem>
                            <SimpleItem dataField="bik">
                                <Label text={`БИК`} />
                            </SimpleItem>
                            <SimpleItem dataField="companyName">
                                <Label text={`Ооо/ип`} />
                            </SimpleItem>
                        </GroupItem>
                        <GroupItem>
                            <SimpleItem dataField="cor/s">
                                <Label text={`Кор. счёт`} />
                            </SimpleItem>
                            <SimpleItem dataField="ogrn">
                                <Label text={`Огрн`} />
                            </SimpleItem>
                        </GroupItem>
                        <GroupItem>
                            <SimpleItem dataField="inn">
                                <Label text={`Инн`} />
                            </SimpleItem>
                            <SimpleItem dataField="bank">
                                <Label text={`Банк`} />
                                <RequiredRule message="Поле обязательно к заполнению" />
                            </SimpleItem>
                        </GroupItem>
                        <GroupItem>
                            <SimpleItem dataField="r/s">
                                <Label text={`Р/C`} />
                            </SimpleItem>
                        </GroupItem>
                    </GroupItem>
                    <ButtonItem horizontalAlignment="left"
                                buttonOptions={buttonOptions}
                    />
                </Form>
            </div>
        </React.Fragment>
    );
}

export default ProfileBank;
