import React from 'react';
import Form, {ButtonItem, GroupItem, Label, RequiredRule, SimpleItem} from "devextreme-react/form";

const sex = [
    'male',
    'female',
];

function ProfileUser({dataUser, handleSaveUserData, formFieldDataChanged}) {
    let positionEditorOptions = { items: sex, searchEnabled: true };
    let buttonOptions = {
        text: 'Сохранить',
        type: 'success',
        useSubmitBehavior: true,
        onClick: handleSaveUserData
    };
    let phoneEditorOptions = {
        mask: '+7 (000) 000-0000',
        maskRules: {
            X: /[01-9]/,
        },
    };
    return (
        <React.Fragment>
            <h2 className={'content-block'}>Данные профиля</h2>
            <div className={'content-block dx-card responsive-paddings'}>
                <Form
                    formData={dataUser}
                    showColonAfterLabel={true}
                    onFieldDataChanged={formFieldDataChanged}
                    showValidationSummary={true}
                    validationGroup="customerData"
                    labelLocation={'top'}
                >
                    <GroupItem cssClass="second-group" colCount={2}>
                        <GroupItem>
                            <SimpleItem editorOptions={{
                                readOnly: true
                            }} dataField="email">
                                <Label text={`Email`} />
                            </SimpleItem>

                            <SimpleItem dataField="name">
                                <Label text={`Никнейм`} />
                                <RequiredRule message="Поле обязательно к заполнению" />
                            </SimpleItem>
                        </GroupItem>
                        <GroupItem>
                            <SimpleItem dataField="firstName" >
                                <Label text={`Имя`} />
                            </SimpleItem>
                            <SimpleItem dataField="lastName">
                                <Label text={`Фамилия`} />
                            </SimpleItem>
                        </GroupItem>
                        <GroupItem>
                            <SimpleItem editorOptions={phoneEditorOptions} dataField="phone" >
                                <Label text={`Телефон`} />
                            </SimpleItem>
                            <SimpleItem editorType="dxDateBox"
                                        dataField="dateOfBirth" >
                                <Label text={`Дата рождения`} />
                            </SimpleItem>
                        </GroupItem>
                        <GroupItem>
                            <SimpleItem dataField="sex"  editorType="dxSelectBox" editorOptions={positionEditorOptions}>
                                <Label text={`Пол`} />
                            </SimpleItem>
                        </GroupItem>
                    </GroupItem>
                    <ButtonItem horizontalAlignment="left"
                                buttonOptions={buttonOptions}
                    />
                </Form>
            </div>
        </React.Fragment>
    );
}

export default ProfileUser;
