export const navigation = [
  {
    text: 'Главная',
    path: '/home',
    icon: 'home'
  },
  {
    text: 'Реестр',
    icon: 'folder',
    items: [
      {
        text: 'Профиль',
        path: '/profile'
      },
      {
        text: 'Услуги',
        path: '/services'
      }
    ]
  }
  ];
