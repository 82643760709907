import React from 'react';
import {useAuth} from "../../contexts/auth";
import {$authHost, providerServiceCRUD} from "../../http";
import {useNavigate, useParams} from "react-router-dom";
import ServiceMainData from "../../components/services/ServiceMainData";
import {Button} from "devextreme-react/button";
import {parseResponse} from "../../http/utils";
import ServiceOtherData from "../../components/services/ServiceOtherData";
import LoadIndicator from "devextreme-react/load-indicator";


export default function ServicePage() {
    const navigate = useNavigate();
    const [loading, setLoading] = React.useState(true)
    const {user, provider} = useAuth();
    const [data, setData] = React.useState({})
    const [dataOther, setDataOther] = React.useState({})
    const [cities, setCities] = React.useState([])
    const [tags, setTags] = React.useState([])
    let {id} = useParams();

    React.useEffect(() => {
        (async function () {
            if (id) {
                try {
                    const {service: data} = await providerServiceCRUD.get(id)
                    console.log(data)
                    const cities = await parseResponse(
                        $authHost.get(`serviceCity/${id}`)
                    )
                    const tags = await parseResponse(
                        $authHost.get(`serviceTags/${id}`)
                    )
                    setTags(tags)
                    setCities(cities)
                    setData(data)
                    setDataOther((prevState => ({...prevState, options: data?.options, sub_services: data?.sub_services, faqs: data?.faqs, tagsId: data?.tags?.map(el => ({id: el.id, name: el.name})),
                        citiesId: data?.city?.map(el => ({id: el.id, name: el.name}))})))
                }
                finally {
                    setLoading(false)
                }
            }

        }())
    }, [id])


    return (
        <React.Fragment>
            <Button onClick={() => {
                navigate(-1)
            }} className={'content-block'} text="Назад"/>
            {loading ? <div className={'content-block dx-card dx-center responsive-paddings'}><LoadIndicator width={"24px"} height={"24px"} visible={true} /></div> : <>
                <ServiceMainData data={data}/>
                <ServiceOtherData tags={tags} data={dataOther} cities={cities}/>
            </>}
        </React.Fragment>
    );
}
