import React from 'react';
import Form, {ButtonItem, GroupItem, Label, RequiredRule, SimpleItem} from "devextreme-react/form";

const typeServiceQuantity = [{
    ID: 'group',
    Name: 'Групповая',
}, {
    ID: 'personal',
    Name: 'Индивидуальная',
}
]

const typeServiceTime = [{
    ID: 'withTime',
    Name: 'Групповая',
}, {
    ID: 'withoutTime',
    Name: 'Без времени',
}
]

const typeServiceConfirm = [{
    ID: 'withConfirm',
    Name: 'С подтверждение',
}, {
    ID: 'withoutConfirm',
    Name: 'Без подтверждения',
}
]

function ServiceMainData({data}) {
    let typeServiceQuantityEditorOptions = {
        items: typeServiceQuantity, searchEnabled: true, displayExpr: "Name",
        valueExpr: "ID"
    }
    let typeServiceTimeEditorOptions = {
        items: typeServiceTime, searchEnabled: true, displayExpr: "Name",
        valueExpr: "ID"
    };
    let typeServiceConfirmEditorOptions = {
        items: typeServiceConfirm, searchEnabled: true, displayExpr: "Name",
        valueExpr: "ID"
    };
    return (
        <React.Fragment>
            <h2 className={'content-block'}>Базовые данные услуги</h2>
            <div className={'content-block dx-card responsive-paddings'}>
                <Form
                    formData={data}
                    showColonAfterLabel={true}
                    showValidationSummary={true}
                    validationGroup="customerData"
                    labelLocation={'top'}
                >
                    <GroupItem cssClass="second-group" colCount={2}>
                        <GroupItem>
                            <SimpleItem dataField="name">
                                <Label text={`Название`}/>
                            </SimpleItem>
                            <SimpleItem editorType="dxNumberBox" dataField="price">
                                <Label text={`Цена трансфера`}/>
                            </SimpleItem>
                        </GroupItem>
                        <GroupItem>
                            <SimpleItem dataField="countPeople">
                                <Label text={`Кол-во человек`}/>
                            </SimpleItem>
                            <SimpleItem dataField="workingHours">
                                <Label text={`График работы`}/>
                            </SimpleItem>
                        </GroupItem>
                        <GroupItem>
                            <SimpleItem dataField="startingPoint">
                                <Label text={`Место начала`}/>
                            </SimpleItem>
                            <SimpleItem dataField="endPoint">
                                <Label text={`Конечная точка`}/>
                            </SimpleItem>
                        </GroupItem>
                        <GroupItem>
                            <SimpleItem editorType="dxDateBox" dataField="expirationDateStart">
                                <Label text={`Срок действия (от)`}/>
                            </SimpleItem>
                            <SimpleItem editorType="dxDateBox" dataField="expirationDateEnd">
                                <Label text={`Срок действия (до)`}/>
                            </SimpleItem>
                        </GroupItem>
                        <GroupItem>
                            <SimpleItem dataField="typeServiceQuantity" editorType="dxSelectBox"
                                        editorOptions={typeServiceQuantityEditorOptions}>
                                <Label text={`Тип услуги (количество)`}/>
                            </SimpleItem>
                            <SimpleItem dataField="typeServiceTime" editorType="dxSelectBox"
                                        editorOptions={typeServiceTimeEditorOptions}>
                                <Label text={`Тип услуги (время)`}/>
                            </SimpleItem>
                        </GroupItem>
                        <GroupItem>
                            <SimpleItem dataField="typeServiceConfirm" editorType="dxSelectBox"
                                        editorOptions={typeServiceConfirmEditorOptions}>
                                <Label text={`Тип услуги (подтверждение)`}/>
                            </SimpleItem>
                            <SimpleItem editorType="dxCheckBox" dataField="active">
                                <Label text={`Активная`}/>
                            </SimpleItem>

                        </GroupItem>
                    </GroupItem>
                </Form>
            </div>
        </React.Fragment>
    );
}

export default ServiceMainData;
