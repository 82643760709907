import React from 'react';
import {TagBox, DataGrid} from "devextreme-react";
import Form, {SimpleItem} from "devextreme-react/form";
import {Column} from "devextreme-react/data-grid";


function ServiceOtherData({cities, data, tags}) {
    return (
        <React.Fragment>
            <h2 className={'content-block'}>Остальные данные услуги</h2>
            <div className={'content-block dx-card responsive-paddings'}>
                <Form formData={data}
                      validationGroup="formEditor">
                    <SimpleItem>
                        <div className="dx-field">
                            <div className="dx-field-label item-label">
                                Города
                            </div>

                            <div className="dx-field-value">
                                <TagBox
                                    dataField="citiesId"
                                    defaultValue={data?.citiesId}
                                    dataSource={cities}
                                    searchEnabled={true}
                                    valueExpr="id"
                                    displayExpr="name"
                                    showClearButton={true}
                                />
                            </div>

                        </div>
                        <div className="dx-field">
                            <div className="dx-field-label item-label">
                                Теги
                            </div>

                            <div className="dx-field-value">
                                <TagBox
                                    dataField="tagsId"
                                    defaultValue={data?.tagsId}
                                    dataSource={tags}
                                    searchEnabled={true}
                                    valueExpr="id"
                                    displayExpr="name"
                                    showClearButton={true}
                                />
                            </div>

                        </div>
                        <div className="dx-field">
                            <div className="dx-field-label item-label">
                                Вопрос-ответ
                            </div>

                            <div className="dx-field-value">
                                <DataGrid
                                    dataSource={data?.faqs}
                                    showBorders={true}
                                >
                                    <Column dataField={'id'} caption="Идентификатор"/>
                                    <Column dataField={'title'} caption="Название"/>
                                    <Column dataField={'description'} caption="Описание"/>
                                </DataGrid>
                            </div>
                        </div>
                        <div className="dx-field">
                            <div className="dx-field-label item-label">
                                Опции
                            </div>

                        </div>
                        <DataGrid
                            dataSource={data?.options}
                            showBorders={true}
                            style={{marginBottom: '30px'}}
                        >
                            <Column dataField={'id'} caption="Идентификатор"/>
                            <Column dataField={'optionsName'} caption="Название"/>
                            <Column dataField={'optionsCount1'} caption="Кол-во человек (от)"/>
                            <Column dataField={'optionsCount2'} caption="Кол-во человек (до)"/>
                            <Column dataField={'payment'} caption="Оплата"/>
                            <Column dataField={'duration'} caption="Длительность"/>
                            <Column dataField={'durationType'} caption="Длительность (тип)"/>
                            <Column dataField={'optionsOldPrice'} caption="Старая цена (опции)"/>
                            <Column dataField={'optionsNewPrice'} caption="Цена (опции)"/>
                        </DataGrid>
                        <div className="dx-field">
                            <div className="dx-field-label item-label">
                                Подуслуги
                            </div>

                        </div>
                        <DataGrid
                            dataSource={data?.sub_services}
                            showBorders={true}
                        >
                            <Column dataField={'id'} caption="Идентификатор"/>
                            <Column dataField={'name'} caption="Название услуги"/>

                            <Column dataField={'leftDescription'} caption="Левое описание"/>
                            <Column dataField={'rightDescription'} caption="Правое описание"/>
                            <Column dataField={'fullDescription'} caption="Полное описание"/>
                            <Column dataField={'shortDescription'} caption="Краткое описание"/>
                            <Column dataField={'url'} caption="URL услуги"/>
                        </DataGrid>
                    </SimpleItem>
                </Form>
            </div>
        </React.Fragment>
    );
}

export default ServiceOtherData;
