import CustomStore from "devextreme/data/custom_store";
import {providerServiceCRUD} from "../http";

const getDataStore = () => {
  return new CustomStore({
    key: "id",
    load: (data) => {
      return providerServiceCRUD.search(data)
        .then((data) => {
          return {
            data: data.entities,
            summary: data.summary,
            totalCount: data.totalCount,
          };
        })
    },
    insert: function (values) {
        return providerServiceCRUD.create(values)
    },
    update: function (key, values) {
        return providerServiceCRUD.edit({id: key, ...values})
    },
    remove: function (key) {
        return providerServiceCRUD.delete(key)
    },
    byKey: (key) => {
        return providerServiceCRUD.get(key)
    },
  });
};

export default getDataStore;
